<template>
    <div class="main_container">
        <div v-if="!accessToken && !userData">
            <NavBar/>
        </div>
        <div class="agreement_content" :class="{'agreement_top': accessToken && userData}">
            <div class="agreement_logo"></div>
            <div class="agreement_title">
                SUBSCRIPTION AGREEMENT
            </div>
            <div class="table_content">
                <b-table :items="companyItems" :fields="fields" responsive="sm">
                    <template v-slot:cell(company)="data">
                        <div>
                            <div class="item">
                                <span>Name: </span>{{data.item.name}}
                            </div>
                            <div class="item">
                                <span>Contact: </span>{{data.item.contact}}
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
            <div class="table_content default">
                <table>
                    <thead>
                    <tr class="head_title">
                        <th>
                            SERVICES INFORMATION
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="column_title">Description of Services:</td>
                        <td class="description">
                            Subscription Services: Access to the Rezi Leads’ proprietary CRM and automated lead
                            generation for Real Estate Agents. By agreeing to this Subscription Agreement, client
                            declares that they have read, and are in agreement with Billing Policies, Terms of Service,
                            and Privacy Policies agreements.
                            Support and Maintenance Services: For support, please contact support@rezileads.com
                            <i>The term “Services” shall be deemed to include any Subscription Services, Implementation
                            Services, Support and Maintenance Services, and Additional Services, including any software,
                                documentation or data related to any of the foregoing.</i>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_content default">
                <table>
                    <thead>
                    <tr class="head_title">
                        <th>
                            CONTRACT TERM DETAILS
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="column_title">Subscription Term:</td>
                        <td class="description">
                            12 Months, beginning on the Effective Date. Client understands that they will not be able to
                            terminate the contract prior to the end of the initial service term. At the end of the
                            Subscription Term, your Account will automatically convert to a month-to-month plan at
                            then-current pricing, unless you a) agree to a new Subscription Agreement, or b) send
                            written notice of cancellation to support@ReziLeads.com no less than 10 days prior to the
                            end of the Subscription Term and receive an email response acknowledging your cancellation
                            request
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="table_content default">
                <table>
                    <thead>
                    <tr class="head_title">
                        <th>
                            PAYMENT DETAILS
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border_tr">
                        <td class="column_title">Services Fees:</td>
                        <td class="description">
                            Recurring Fees: $300 per month. <br/>
                            Onboarding Fees: $300 at the start of Subscription Term <br/>
                            <i>The term “Services Fees” shall be deemed to include any Recurring Fees, Upfront Fees,
                                Onboarding Fees or Additional Fees payable under this Agreement.</i>

                        </td>
                    </tr>
                    <tr class="border_tr">
                        <td class="column_title">Ad Budget:</td>
                        <td class="description">
                            Client understands that it has to pay a minimum ad budget of $300 per month. If Client
                            decides to terminate ad budget, client understands that they will not receive any leads.
                            Client understands that, by terminating ad budget, client will still be responsible for all
                            Service Fees until the end of Subscription Term.
                        </td>
                    </tr>
                    <tr>
                        <td class="column_title">Payment Terms:</td>
                        <td class="description">
                            Upfront Fees shall be payable with the first payment of Services Fees paid by Client under
                            this Agreement. <br/> <br/>
                            Recurring Fees shall be payable monthly in advance.
                            All Services Fees shall be payable in according with the Terms of this Agreement and the
                            Billing Policies Agreement.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="agreement_sub-content">
                <div class="agreement_sub-title">AGREEMENT:</div>
                <p class="agreement_sub-description">This Services Agreement (“Agreement”) is entered into on the
                    Effective Date set forth above (the
                    “Effective Date”) between the Company (the “Company”) identified above and the Client identified
                    above (the “Client”). This Agreement includes and hereby incorporates by reference, and Client
                    agrees to be bound by, the information set forth above and the Terms and Conditions attached hereto
                    as <u>Exhibit A.</u> Furthermore, this Agreement contains, among other things, warranty disclaimers,
                    liability limitations and use limitations. There shall be no force or effect to any different terms
                    of any related purchase order, contract, agreement or similar form, even if signed by the parties
                    before or after the date hereof. Furthermore, Client agrees to the terms set forth in the Billing
                    Policies, Terms of Service, and Privacy Policy provided separately. </p>
            </div>
            <div class="agreement_sub-item">
                EXHIBIT A
            </div>
            <div class="agreement_sub-item">
                TERMS AND CONDITIONS
            </div>
            <div class="agreement_points">
                <ol>
                    <li>
                        <span>SERVICES AND SUPPORT.</span>
                        <ol>
                            <li>
                                <span>Company Provision of Services. </span> Subject to the terms of this Agreement,
                                including any Service Capacity set forth herein (if any), Company will provide to Client
                                the Services set forth above. Company shall use reasonable efforts consistent with
                                prevailing industry standards to maintain the Services in a manner which minimizes
                                errors and interruptions in the Services and shall perform the Services in a
                                professional and workmanlike manner.
                            </li>
                            <li>
                                <span>License. </span> The Services are provided by Company as
                                “software-as-a-service,” and thus Client does not have a license to download any aspect
                                of the Services, except as expressly permitted or intended through the Services. Client
                                is hereby granted, during the Term and subject to and limited by the terms of this
                                Agreement, including any Service Capacity set forth herein (if any), a non-exclusive,
                                non-sublicenseable, non-transferable, non-assignable, limited, revocable license to
                                access and use the Services, conditioned on compliance with this Agreement. With respect
                                to any Services that are provided through distribution of software or documentation, or
                                are otherwise provided to Client for use on Client premises or devices, Company hereby
                                grants Client a non-exclusive, non-transferable, non-sublicensable license to use such
                                distributed Services during the Term and conditioned on compliance with this Agreement,
                                only in connection with the Services.
                            </li>
                            <li>
                                <span>Restrictions. </span> The Services may be used only for Client’s internal
                                business purposes and not for any commercialization by Client. Furthermore, Client will
                                not, directly or indirectly: (i) reverse engineer, decompile, copy, mirror, disassemble
                                or otherwise attempt to discover or reproduce the source code, object code or underlying
                                structure, feature, function, user interface, ideas, know-how or algorithms relevant to
                                the Services; (ii) modify, translate, or create derivative works based on the Services
                                (except to the extent expressly permitted by Company or authorized within the Services);
                                (iii) use or make available any portion of the Services for any timesharing, commercial
                                or service bureau purposes or otherwise for the benefit of a third party, including as
                                an outsourcing offering, except as expressly set forth herein; (iv) build or create
                                applications, programs or services that are competitive with the Services; (v) remove
                                any proprietary notices or labels; or (vi) use the Services beyond the Service Capacity
                                or other usage limits set forth in this Agreement.
                            </li>
                            <li>
                                <span>Support and Maintenance Obligations. </span> For support and maintenance,
                                please contact support@rezileads.com. Client understands that Rezi Leads does not
                                guarantee a certain amount of leads, and in some instances, client may receive zero
                                leads. Client understands that Rezi Leads does not guarantee that any leads provided by
                                Rezi Leads will result in a transaction for the Client’s business.
                            </li>
                            <li>
                                <span>Company Monitoring. </span> Although Company has no obligation to monitor
                                Client’s use of the Services, Company may do so and may prohibit any use of the Services
                                it believes may be (or alleged to be) in violation of this Agreement.
                            </li>
                            <li>
                                <span>Control Over Services. </span> So long as during the Term Company does not
                                materially decrease the functionality of Services as described in this Agreement: (i)
                                Company retains sole control over the operation, provision, maintenance, management, and
                                performance of the Services, including the selection, deployment, modification and
                                replacement of any software component of the Services, and maintenance, upgrades,
                                corrections or repairs thereof; (ii) Company reserves the right to make any changes to
                                any software component of the Services that it deems necessary or useful to maintain or
                                enhance the quality or delivery of Company’s Services to its customers, the competitive
                                strength of or market for Company’s Services, or the cost efficiency or performance of
                                the Services; and (iii) Company may use global resources to provide Services and perform
                                its obligations, unless otherwise expressly agreed.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>CLIENT RESPONSIBILITIES; RESTRICTIONS.</span>
                        <ol>
                            <li>
                                <span>Account Creation.</span> As part of the registration process, Client will identify
                                an
                                administrative user name and password for Client’s Company account. Client shall be
                                solely
                                responsible for maintaining, remembering and keeping confidential Client’s account
                                information, including user name and password. Company reserves the right to refuse
                                registration of, or cancel passwords it deems inappropriate. Client shall be responsible
                                for
                                maintaining the security of the Client account, passwords (including but not limited to
                                administrative and user passwords) and files, and for all uses of Client account with or
                                without Client’s knowledge or consent.
                            </li>
                            <li>
                                <span>Client Representations.</span>
                                Client represents, covenants, and warrants that Client will use
                                the Services only in compliance with this Agreement and with Company’s standard
                                published
                                policies then in effect, including as published on Company’s websites or within any
                                application through which the Services are available (the “Policy”) and all applicable
                                laws
                                and regulations.
                            </li>
                            <li>
                                <span>Additional Equipment Responsibilities.</span> Client shall be responsible for
                                obtaining and
                                maintaining any equipment and ancillary services needed to connect to, access or
                                otherwise
                                use the Services, including, without limitation, modems, hardware, servers, software,
                                operating systems, networking, web servers and the like (collectively, “Equipment”).
                                Client
                                shall also be responsible for maintaining the security of the Equipment, and for all
                                uses of
                                the Equipment with or without Client’s knowledge or consent.
                            </li>
                            <li>
                                <span>Third Party Terms.</span> Client is responsible for complying with all terms of
                                use for any software, content, service or website it loads, creates or accesses when
                                using the Services.
                            </li>
                            <li>
                                <span>Client Data.</span> Client may be required to provide information or data to
                                Company to enable the provision
                                of the Services, and Company may collect data or information from Client by or through
                                the
                                access or use of the Services or any Software (“Client Data”). Client hereby grants
                                Company
                                a non-exclusive, worldwide, royalty-free right and license to any intellectual property,
                                including Client Data, that is necessary for Company and its designees to perform the
                                Services. Client has sole responsibility for the accuracy, quality, and legality of any
                                Client Data, including the means by which it was acquired by Client.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>CONFIDENTIALITY; PROPRIETARY RIGHTS.</span>
                        <ol>
                            <li>
                                <span>Confidentiality. </span> Each party (the “Receiving Party”) understands that the
                                other party (the “Disclosing Party”) has disclosed or may disclose confidential
                                business, technical or financial information relating to the Disclosing Party’s business
                                (hereinafter referred to as “Proprietary Information” of the Disclosing Party).
                                Proprietary Information of Company includes non-public information regarding features,
                                functionality and performance of the Services. Proprietary Information of Client
                                includes non-public Client Data. The Receiving Party agrees: (i) to take reasonable
                                precautions to protect such Proprietary Information, and (ii) not to use (except in
                                performance of the Services or as otherwise permitted herein) or divulge to any third
                                person any such Proprietary Information. The Disclosing Party agrees that the foregoing
                                obligations of confidentiality and non-use shall apply indefinitely or until the
                                Receiving Party can prove with clear documented evidence that such Proprietary
                                Information (a) is or has become generally available to the public without violation by
                                Receiving Party of its obligations hereunder, (b) was in Receiving Party’s possession or
                                known by it prior to receipt from the Disclosing Party, (c) was rightfully disclosed to
                                Receiving Party without restriction by a third party, (d) was independently developed by
                                Receiving Party without use of or reference to any of Disclosing Party’s Proprietary
                                Information, or (e) is required to be disclosed by law, provided that Receiving Party
                                has taken all reasonable actions to minimize any such legally required disclosure to the
                                greatest extent reasonably possible.
                            </li>
                            <li>
                                <span>Ownership Rights. </span> Client shall own all right, title and interest in and
                                to the Client Data. Company shall own and retain all right, title and interest in and to
                                (a) the Services, all improvements, enhancements or modifications thereto, (b) any
                                software, applications, inventions or other technology developed in connection with the
                                Services or any support, (c) any suggestions or feedback provided to Company by Client,
                                and (d) all intellectual property rights related to any of the foregoing. No transfer of
                                ownership of any intellectual property will occur under this Agreement. No rights or
                                licenses are granted except as expressly set forth herein. If deliverables are created
                                by Company specifically for Client and identified as such in supporting material,
                                Company hereby grants Client a worldwide, non-exclusive, fully paid, royalty-free
                                license to reproduce and use copies of the deliverables internally only.
                            </li>
                            <li>
                                <span>Data Collection. </span> Notwithstanding anything to the contrary, but subject
                                to the confidentiality obligations contained herein, Company shall have the right to
                                collect and analyze data and other information relating to the provision, use and
                                performance of various aspects of the Services and related systems and technologies
                                (including, without limitation, information concerning Client Data and data derived
                                therefrom), and Company will be free (during and after the term hereof) to (i) use such
                                information and data to improve and enhance the Services and for other development,
                                diagnostic and corrective purposes in connection with the Services and other Company
                                offerings, and (ii) disclose such data in aggregate or other form in connection with its
                                business, subject to any applicable privacy laws, and use such data for business
                                purposes including analytics, product benchmarking, and marketing. Company may use
                                Client Data according to any privacy policies applicable to provision of the Services
                                and as necessary to provide the Services (including preventative and reactive technical
                                support), or as permitted by Client, or as otherwise required by law.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>PAYMENT OF FEES.</span>
                        <ol>
                            <li>
                                <span>Services Fees.  </span> Client will pay Company the then applicable Services Fees
                                for the Services in accordance with the Payment Terms. If Client’s use of the Services
                                exceeds the Service Capacity or otherwise requires the payment of additional fees (per
                                the terms of this Agreement), Client shall be billed for such usage and Client agrees to
                                pay the additional fees in the manner provided herein (and such additional fees shall be
                                deemed to be Services Fees for purposes of this Agreement). Company reserves the right
                                to change the Services Fees or applicable charges and to institute new charges and
                                Services Fees at the end of the Initial Service Term or then current Renewal Term (if
                                applicable), upon thirty (30) days prior notice to Client (which may be sent by email).
                                If Client believes that Company has billed Client incorrectly, Client must contact
                                Company no later than 60 days after the closing date on the first billing statement in
                                which the error or problem appeared, in order to receive an adjustment or credit.
                                Inquiries should be directed to Company’s Client support department.
                            </li>
                            <li>
                                <span>Late Fees. </span> Unpaid Services Fees are subject to a finance charge of 1.5%
                                per month on any outstanding balance, or the maximum permitted by law, whichever is
                                lower, plus all expenses of collection and may result in immediate termination of
                                Service.
                            </li>
                            <li>
                                <span>Taxes. </span> Client shall be responsible for all taxes associated with
                                Services other than U.S. taxes based on Company’s net income.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>TERM AND TERMINATION.</span>
                        <ol>
                            <li>
                                <span>Term. </span> Subject to earlier termination as provided below, the term of
                                this Agreement (the “Term”) shall be for the Initial Service Term plus any Renewal Terms
                                (if applicable).
                            </li>
                            <li>
                                <span>Termination For Cause. </span> In addition to any other remedies it may have,
                                Rezi Leads may also terminate this Agreement upon thirty (30) days’ notice (or without
                                notice in the case of nonpayment), if the other party materially breaches any of the
                                terms or conditions of this Agreement and does not cure such breach within ten (10) days
                                of such notice. If Client becomes insolvent, unable to pay debts when due, files for or
                                is subject to bankruptcy or receivership or asset assignment, the other party may
                                terminate this Agreement and cancel any unfulfilled obligations. Client will pay in full
                                for the Services up to and including the last day on which the Services are provided.
                                Client does not have right to terminate until subscription term is fulfilled.
                            </li>
                            <li>
                                <span>Survival. </span> All sections of this Agreement which by their nature should
                                survive termination will survive termination, including, without limitation, accrued
                                rights to payment, confidentiality obligations, warranty disclaimers, and limitations of
                                liability.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>WARRANTY AND DISCLAIMER.</span>
                        COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE
                        ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES. EXCEPT AS
                        EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED “AS IS” AND COMPANY DISCLAIMS ALL
                        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.

                    </li>
                    <li>
                        <span>INDEMNITY </span>
                        <ol>
                            <li>
                                <span>Company Indemnity Obligations. </span> Company shall hold Client harmless from
                                liability to third parties resulting from infringement by the Service of any United
                                States patent or any copyright or misappropriation of any trade secret, provided Company
                                is promptly notified of any and all threats, claims and proceedings related thereto and
                                given reasonable assistance and the opportunity to assume sole control over defense and
                                settlement; Company will not be responsible for any settlement it does not approve in
                                writing.
                            </li>
                            <li>
                                <span>Exclusions from Company Indemnity Obligations. </span> The foregoing
                                obligations do not apply with respect to portions or components of the Service (i) not
                                supplied by Company, (ii) made in whole or in part in accordance with Client
                                specifications, (iii) that are modified after delivery by Company, (iv) combined with
                                other products, processes or materials where the alleged infringement relates to such
                                combination, (v) where Client continues allegedly infringing activity after being
                                notified thereof or after being informed of modifications that would have avoided the
                                alleged infringement, or (vi) where Client’s use of the Service is not strictly in
                                accordance with this Agreement.
                            </li>
                            <li>
                                <span>Replacement of Infringing Services. </span> If, due to a claim of infringement,
                                the Services are held by a court of competent jurisdiction to be or are believed by
                                Company to be infringing, Company may, at its option and expense (a) replace or modify
                                the Service to be non-infringing provided that such modification or replacement contains
                                substantially similar features and functionality, (b) obtain for Client a license to
                                continue using the Service, or (c) if neither of the foregoing is commercially
                                practicable, terminate this Agreement and Client’s rights hereunder and provide Client a
                                refund of any prepaid, unused fees for the Service.
                            </li>
                            <li>
                                <span>Client Indemnification. </span> Client hereby agrees to indemnify and hold
                                harmless Company against any damages, losses, liabilities, settlements and expenses
                                (including without limitation costs and attorneys’ fees) in connection with any claim or
                                action that arises from an alleged violation of this Agreement or otherwise from
                                Client’s use of Services or as a result of Company’s reliance on any Client Data or use
                                of Client Data in connection with the provision of the Services.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span>LIMITATION OF LIABILITY</span>
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED
                        TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS
                        AND EMPLOYEES, SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS
                        AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, TORT, STRICT
                        LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR
                        CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF
                        BUSINESS OR LOST REVENUES, PROFITS OR DOWNTIME COSTS, OR FOR ANY DATA BREACHES OR HACKS; (B) FOR
                        ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL COSTS OR DAMAGES; (C) FOR ANY
                        MATTER BEYOND COMPANY’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS
                        ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CLIENT TO COMPANY FOR THE SERVICES
                        UNDER THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH
                        CASE, WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </li>
                    <li>
                        <span>MISCELLANEOUS</span>
                        <ol>
                            <li>
                                <span>Severability. </span> If any provision of this Agreement is found to be
                                unenforceable or invalid, that provision will be limited or eliminated to the minimum
                                extent necessary so that this Agreement will otherwise remain in full force and effect
                                and enforceable.
                            </li>
                            <li>
                                <span>Assignment. </span> This Agreement is not assignable, transferable or
                                sublicensable by Client except with Company’s prior written consent. Company may
                                transfer and assign any of its rights and obligations under this Agreement without
                                consent.
                            </li>
                            <li>
                                <span>Entire Agreement. </span> This Agreement is the complete and exclusive
                                statement of the mutual understanding of the parties and supersedes and cancels all
                                previous written and oral agreements, communications and other understandings relating
                                to the subject matter of this Agreement, and that all waivers and modifications must be
                                in a writing signed by both parties, except as otherwise provided herein.
                            </li>
                            <li>
                                <span>Relationship. </span> No agency, partnership, joint venture, or employment is
                                created as a result of this Agreement and Client does not have any authority of any kind
                                to bind Company in any respect whatsoever.
                            </li>
                            <li>
                                <span>Attorney Fees. </span> In any action or proceeding to enforce rights under this
                                Agreement, the prevailing party will be entitled to recover costs and attorneys’ fees.
                            </li>
                            <li>
                                <span>Notice. </span> All notices under this Agreement will be in writing and will be
                                deemed to have been duly given when received, if personally delivered; the day after it
                                is sent, if transmitted by facsimile or e-mail or if sent for next day delivery by
                                recognized overnight delivery service; and upon receipt, if sent by certified or
                                registered mail, return receipt requested.
                            </li>
                            <li>
                                <span>Governing Law. </span> This Agreement shall be governed by the laws of the
                                State where Company’s current headquarters are located, without regard to its conflict
                                of laws provisions.
                            </li>
                            <li>
                                <span>Publicity. </span> Client agrees to reasonably cooperate with Company to serve
                                as a reference account upon request, and hereby grants Company a license to display
                                Client’s name and logo on its website and other marketing and sales materials to
                                communicate that Client is a customer of Company.
                            </li>
                            <li>
                                <span>Export Restrictions. </span> Client may not remove or export from the United
                                States or allow the export or re-export of the Services or anything related thereto, or
                                any direct product thereof in violation of any restrictions, laws or regulations of the
                                United States Department of Commerce, the United States Department of Treasury Office of
                                Foreign Assets Control, or any other United States or foreign agency or authority. The
                                Services and related documentation are to be deemed “commercial items,” “commercial
                                computer software” and “commercial computer software documentation” for applicable DFAR
                                and FAR regulations. Any use modification, reproduction, release, performance, display,
                                or disclosure of commercial software or commercial software documentation by the U.S.
                                Government will be governed solely by the terms of this Agreement and will be prohibited
                                except to the extent expressly permitted by the terms of this Agreement
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
        <div v-if="!accessToken && !userData">
            <Footer/>
        </div>

    </div>
</template>

<script>
    import NavBar from "../components/NavBar";
    import Footer from "../components/Footer";
    import {mapGetters} from "vuex";

    export default {
        name: "BuyerOrSellerAgreement",
        components: {NavBar},
        data() {
            return {
                fields: [
                    {
                        key: 'company',
                        label: 'Company Information:'
                    }
                ],
                companyItems: [
                    {
                        name: ' REZI LEADS INC. and its affiliated entities (“Company”, “us”, “our”, and “we”) support ',
                        contact: ' support@rezileads.com'

                    }
                ],
            }
        },
        computed: {
            ...mapGetters(['accessToken', 'userData'])
        },
        components: {
            NavBar,
            Footer
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .main {
        &_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .agreement {
        &_content {
            width: 100%;
            padding: 100px 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1640px;
            margin: 0 auto;
            &.agreement_top {
                padding-top: 20px !important;
                & .agreement_logo {
                    display: block;
                }
            }

            & .table_content {
                width: 100%;
                margin: 15px 0;

                ::v-deep .table thead th {
                    color: $main_blue;
                    font-size: 18px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                ::v-deep tbody tr td {
                    padding: 10px 0;
                }

                & .item {
                    border-bottom: 1px solid #dee2e6;
                    padding: 5px 0 10px;

                    & span {
                        padding: 0 10px;
                        color: $main_blue;
                        font-weight: 600;
                    }
                }

                &.default {
                    table {
                        border: 1px solid #dee2e6;
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        ::v-deep .head_title {
                            width: 100% !important;
                            display: flex;
                            justify-content: center;
                            border-bottom: 1px solid #dee2e6;

                            th {
                                color: $main_blue;
                                font-weight: 600;
                                padding: 5px 0;
                            }
                        }

                        tbody {
                            tr {
                                display: flex;
                                align-items: flex-start;

                                &.border_tr {
                                    border-bottom: 1px solid #dee2e6;
                                }

                                .description {
                                    align-items: flex-start;
                                    width: 80%;
                                    padding: 10px;
                                    border-left: 1px solid #dee2e6;
                                }
                            }

                            & .column_title {
                                width: 20%;
                                padding: 10px;
                                color: $light_blue;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        &_logo {
            display: none;
            width: 240px;
            height: 60px;
            background: url("~@/assets/images/Logo.svg") no-repeat center;
            background-size: contain;
            margin-bottom: 20px;
        }

        &_back-btn {
            padding: 0 30px;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            cursor: pointer;
        }

        &_title {
            padding: 0 0 30px;
            font-size: 36px;
            font-weight: 600;
            text-decoration: underline;
            color: $darkBlue;
        }

        &_sub {
            &-content {
                padding: 40px 0 20px;
            }

            &-title {
                color: $darkBlue;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
            }

            &-description {
                padding: 10px 0;
                font-weight: 500;
                font-size: 18px;
            }

            &-item {
                font-size: 24px;
                text-decoration: underline;
                color: $main_blue;
                font-weight: 500;
            }

        }

        &_points {
            padding: 10px 10px 40px;
            display: flex;
            justify-content: flex-start;
            width: 100%;

            ol {
                list-style-type: none;
                counter-reset: item;
                margin: 0;
                padding: 0;
            }

            & li {
                padding: 10px 0;
                display: table;
                counter-increment: item;
                margin-bottom: 0.6em;

                & ul {
                    margin-left: 15px;

                    & li {
                        list-style-type: circle;
                    }
                }

                & li {
                    margin: 0;

                    &:before {
                        content: counters(item, ".") " ";
                    }
                }

                &:before {
                    content: counters(item, ".") ". ";
                    display: table-cell;
                    padding-right: 0.6em;
                    color: $light_blue;
                    font-weight: 600;
                }

                & span {
                    color: $light_blue;
                    font-weight: 600;
                }

                ::v-deep .list_circle {
                    margin-left: 0;

                    li {
                        margin-left: 15px;

                        &:before {
                            list-style-type: circle !important;
                            content: 'o';
                        }

                    }
                }
            }
        }
    }
</style>